import React from "react";
import { Layout } from "../components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/bg_legales.jpg";
import DownloadIcon from "../imgs/download-icon.svg";
import "./comisiones.css";

const ComisionesPage = () => {
    const pageTitle = 'Aranceles y Comisiones | Balanz';
    const pageDesc = 'Somos Balanz, especialistas en inversiones con una trayectoria de 20 años en el mercado de capitales local. Contamos con más 200.000 clientes.';



const page = (
    <>
        <ProductBanner
            bannerContainerClassName="bannerLegales"
            bannerTrasparecy="bannerMask"
            productTitle="Aranceles y comisiones"
            bannerImage={Banner}
            bannerImageClassName="cedearsBannerImage"
            textContainerClassName="textContainer"
            bannerText={false}
            bannerTextClassName="bannerTextInversiones"
            bannerImageMobile={Banner}
            mobile={false}
        />
        <section className="fullwidth legales" style={{paddingBottom: "1rem"}}>
            <Container>
                <Row className="g-0 d-flex justify-content-start" >
                    <Col xs={12} className="pb-0 pt-sm-5">
                        <h2 className="comisiones-title">Tabla de comisiones Balanz 2025</h2>
                        {/* <p className='base-text mb-5'>Se hace saber que, en función a los volúmenes y frecuencia de operaciones podrán aplicarse bonificaciones. La presente tabla de aranceles y comisiones fue aprobada por Acta de Directorio N° 231 de fecha 17 de enero de 2025.</p> */}
                        <div className="table-responsive mt-3">
                            <table className="w-100 comisiones-table table">
                                <thead>
                                    <th className='text-center base-text'>Conceptos</th>
                                    <th className='text-center base-text'>Trading online</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='base-text'>Apertura de cuenta</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Mantenimiento de cuenta</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>* Compra / venta de acciones</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de otros títulos de deuda (públicos)</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de otros títulos de deuda (privados)</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>* Compra / venta de cedears</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra de letras en dólar mep y en dolares cable</th>
                                        <td className='text-center base-text'>Hasta 0.10%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Venta de letras en dólar mep y en dolares cable</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / Venta de letras en pesos</th>
                                        <td className='text-center base-text'>Hasta 0.10%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Compra / venta de Opciones (Acciones, Cedears, Ons, Futuros, Indices)</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Licitaciones de Títulos Públicos y Privados</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Licitación de Letras</th>
                                        <td className='text-center base-text'>Hasta 0.10%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Operaciones por mandato exterior</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                        
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Negociación de cheques diferidos, pagarés y FCE (anual)</th>
                                        <td className='text-center base-text'>Hasta 1.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Operaciones de futuros</th>
                                        <td className='text-center base-text'>Hasta 0.15%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Caución colocadora y tomadora % con detalle de prorrata (cada 90 días) pesos</th>
                                        <td className='text-center base-text'>Hasta 0.50%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Caución colocadora y tomadora % con detalle de prorrata (cada 90 días) dólar </th>
                                        <td className='text-center base-text'>Hasta 0.10%</td>
                                    </tr>
                                    <tr>
                                        <td className='text-center base-text'>&nbsp;</td>
                                        <td className='text-center base-text'>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Renta Títulos Públicos emitidos por Estado Nacional</th>
                                        <td className='text-center base-text'>Hasta 0.05%</td>
                                    </tr>

                                    <tr>
                                        <th className='base-text'>Renta Obligaciones Negociables u otros Títulos Públicos</th>
                                        <td className='text-center base-text'>Hasta 0.35%</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Renta dividendos Acciones Locales</th>
                                        <td className='text-center base-text'>Hasta 1% + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Renta dividendos Cedears</th>
                                        <td className='text-center base-text'>Hasta 0,35% + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Renta dividendos Acciones emitidas en el exterior</th>
                                        <td className='text-center base-text'>Hasta 1% + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Amortización Títulos Valores</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias Monetarias entrantes y salientes en pesos y en dólar mep</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias Monetarias entrantes en dólar cable</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias Monetarias salientes en dólar cable</th>
                                        <td className='text-center base-text'>USD 10 + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Comisión sobre Valores al Cobro</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Comisión por Cheques Rechazados</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias emisoras y receptoras de Títulos Locales</th>
                                        <td className='text-center base-text'>Sin cargo</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias internacionales de Títulos Ley Exterior</th>
                                        <td className='text-center base-text'>** Hasta EUR 3 + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Transferencias internacionales de Títulos Locales Ley Local</th>
                                        <td className='text-center base-text'>** Hasta 25 EUR + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Gastos por Conversión de ADR</th>
                                        <td className='text-center base-text'>** Hasta US$ 0,05 por ADR + Costos del Agente de Conversión + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Gastos por Conversión de Cedears</th>
                                        <td className='text-center base-text'>** Hasta US$ 0,03 por CEDEAR + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>Suscripciones y Rescates F.C.I, del Exterior</th>
                                        <td className='text-center base-text'>** Hasta USD 30 + IVA</td>
                                    </tr>
                                    <tr>
                                        <th className='base-text'>(*) bonificación por trading intradiario 50%</th>
                                        <td className='text-center base-text'>Si</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="mb-0">* Mismo plazo, moneda y especie</p>
                        <p >** El arancel se cobra en pesos ARS</p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth" style={{padding: "0"}}>
            <Container>
                <Row>
                    <Col>
                      <p className='base-text mt-3'>Trading Asistido hasta 1,50% para todas las operaciones</p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth comsiones-footer-text " style={{paddingTop: "2rem"}}>
            <Container>
                <Row className="g-0 d-flex justify-content-start" >
                    <Col xs={12} className="pb-0 ">
                        <p className='base-text'>Balanz se reserva el derecho de modificar en cualquier momento las comisiones y/o aranceles previstos en el presente, lo cual será informado a los inversores por este mismo medio.</p>
                        <p className='base-text'><b>Negociación secundaria:</b> Adicionalmente a los aranceles expuestos se le deberán sumar los derechos de Mercado, aranceles y tasas de registro aplicables a las operaciones que fijen Bolsas y Mercados Argentinos S.A. (BYMA), Matba Rofex S.A., Mercado Abierto Electrónico S.A. (MAE), Mercado Argentino de Valores SA (MAV) y Caja de Valores S.A., vigentes al momento de la operación de acuerdo a lo publicado: 
                        en <a href="https://www.byma.com.ar" target="_blank">www.byma.com.ar</a>, <a href="https://www.matbarofex.com.ar" target="_blank">www.matbarofex.com.ar</a>, <a href="https://www.mae.com.ar" target="_blank">www.mae.com.ar</a> y <a href="https://www.mav-sa.com.ar" target="_blank">www.mav-sa.com.ar</a> y <a href="https://www.cajadevalores.com.ar" target="_blank">www.cajadevalores.com.ar</a>. Esto no aplica a la negociación en las ruedas de segmento bilateral las cuales no establecen derechos de mercado.</p>
                        <p className='base-text'><b>Acreencias:</b> Se deberán sumar los aranceles que fije Caja de Valores S.A. de acuerdo a lo publicado en <a href="https://www.cajadevalores.com.ar" target="_blank">www.cajadevalores.com.ar</a>.</p>
                        <p className='base-text'>El IVA será aplicado según corresponda, sobre el importe de la comisión ó arancel.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
);

return (
    <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"legales"}></Layout>
    </main>
);
};

// Step 3: Export your component
export default ComisionesPage;
